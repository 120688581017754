import { render, staticRenderFns } from "./IdeaCard.vue?vue&type=template&id=54326cb6&scoped=true&"
import script from "./IdeaCard.vue?vue&type=script&lang=js&"
export * from "./IdeaCard.vue?vue&type=script&lang=js&"
import style0 from "./IdeaCard.vue?vue&type=style&index=0&id=54326cb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54326cb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardTitle,VImg,VScaleTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
