<template>
  <div v-intersect.once="(entries) => onIntersect(entries, 'show')">
    <v-scale-transition>
      <v-card
        v-if="show"
        class="idea-card pt-4 pb-3 py-sm-2 py-md-6 px-6 px-sm-4 px-md-5 mx-1 mx-sm-2 mx-md-5 my-1 my-sm-0"
        :color="color"
        elevation="0"
        @click="scrollTo()"
      >
        <v-img class="idea-card-image" :src="resolveUrl" :alt="title" contain />
        <v-card-title
          class="title-text justify-center pa-0 mt-2 mt-sm-5 mt-md-9"
        >
          {{ title }}
        </v-card-title>
      </v-card>
    </v-scale-transition>
  </div>
</template>

<script>
import AnimationTriggerMixin from "@/mixins/AnimationTriggerMixin";

export default {
  name: "IdeaCard",
  mixins: [AnimationTriggerMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    image: {
      type: String,
      default: "../assets/travel_icon.svg",
    },
    targetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    resolveUrl() {
      return require(`@/assets/${this.image}`);
    },
  },
  methods: {
    scrollTo() {
      // navigate to the target element
      document.getElementById(this.targetId).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.idea-card {
  border-radius: 10px;
}

.title-text {
  color: var(--v-textOnSecondary-base);
  font-size: 36px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .title-text {
    font-size: 28px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .idea-card {
    display: flex;
    flex-direction: column;
    aspect-ratio: 1;
    overflow: hidden;
  }

  .idea-card-image {
    flex: 1;
  }

  .title-text {
    font-size: 24px;
  }
}
</style>
