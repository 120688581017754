<template>
  <div :class="`button-container ${dark ? '' : ' sm-link'}`">
    <v-btn
      class="btn"
      :color="dark ? 'text' : 'background'"
      depressed
      @click="$vuetify.goTo(0)"
    >
      <div :class="'d-flex align-center justify-center button-text'+(dark ? ' dark' : '')">
        {{ $t('moreWaysPage.backToTop') }}
        <v-icon class="ml-2 mt-0 mt-md-1">{{ dark ? 'mdi-chevron-up' : 'mdi-triangle' }}</v-icon>
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ButtonLink",
  props: {
    dark: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.button-container {
  margin: 200px auto;
  display: flex;
  justify-content: center;
}

.button-text {
  color: var(--v-primary-base);
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 150%; /* 48px */
  text-transform: uppercase;
}

.button-text.dark {
  color: var(--v-textOnSecondary-base);
  font-weight: 600;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .button-container {
    margin-bottom: 160px;
  }

  .button-container.sm-link {
    justify-content: flex-end;
    margin: 22px 14px 115px;
  }

  .button-container.sm-link .btn {
    max-height: 32px;
  }
  .button-container.sm-link .button-text, .sm-link .v-icon {
    font-size: 16px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .button-container.sm-link {
    justify-content: center;
    margin: 80px auto 45px;
  }
}
</style>