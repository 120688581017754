<template>
  <v-container
      fluid
      class="pa-0"
  >
    <div class="header">
      <h1 class="header__title">{{ $t('moreWaysPage.title') }}</h1>
      <div v-intersect.once="entries => onIntersect(entries, 'showSubtitle')" class="header__subtitle">
        <v-slide-x-reverse-transition>
          <h3 v-if="showSubtitle">
            {{ $t('moreWaysPage.subtitle.line1.part1') }}
            <span class="emphasized-subtitle">{{ $t('moreWaysPage.subtitle.line1.part2') }}</span>
            {{ $t('moreWaysPage.subtitle.line1.part3') }}
          </h3>
        </v-slide-x-reverse-transition>
        <v-slide-x-transition>
          <h3 v-if="showSubtitle">
            {{ $t('moreWaysPage.subtitle.line2.part1') }}
            <span class="emphasized-subtitle">{{ $t('moreWaysPage.subtitle.line2.part2') }}</span>
            {{ $t('moreWaysPage.subtitle.line2.part3') }}
          </h3>
        </v-slide-x-transition>
      </div>
    </div>
    <v-container fluid class="py-0 px-7 px-sm-11 px-md-11" style="max-width: 1280px">
      <v-row no-gutters>
        <v-col class="col-6 col-sm-3">
          <IdeaCard
              color="accentBackground"
              :title="$t('moreWaysPage.travel.shortTitle')"
              image="travel_icon.svg"
              targetId="travel"
          />
        </v-col>
        <v-col class="col-6 col-sm-3">
          <IdeaCard
              color="primaryAction"
              :title="$t('moreWaysPage.journal.title')"
              image="journal_icon.svg"
              targetId="journal"
          />
        </v-col>
        <v-col class="col-6 col-sm-3">
          <IdeaCard
              color="secondaryAccent"
              :title="$t('moreWaysPage.event.shortTitle')"
              image="event_icon.svg"
              targetId="event"
          />
        </v-col>
        <v-col class="col-6 col-sm-3">
          <IdeaCard
              color="accentBackground"
              :title="$t('moreWaysPage.vip.shortTitle')"
              image="vip_icon.svg"
              targetId="vip"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="content-container">
      <WaysToJamInfoContent
          id="travel"
          class="mb-16"
          :title="$t('moreWaysPage.travel.title')"
          feature-image="travel_feature.jpeg"
          banner-icon="travel_icon.svg"
          banner-color="secondary"
          :buttonText="$t('moreWaysPage.travel.buttonText')"
      >
        <template v-slot:content-text>
          <div class="below-header__text">
            {{ $t('moreWaysPage.travel.subtitle.line1') }}
          </div>
          <div class="below-header__text">
            {{ $t('moreWaysPage.travel.subtitle.line2') }}
          </div>
        </template>
        <template v-slot:banner-text-content>
          <span class="banner-text-content">
            {{ $t('moreWaysPage.travel.bannerText.part1') }}<span class="emphasized">{{ $t('moreWaysPage.travel.bannerText.part2') }}</span>
          </span>
        </template>
        <template v-slot:action-text>
          {{ $t('moreWaysPage.travel.promptText') }}
        </template>
      </WaysToJamInfoContent>
      <WaysToJamInfoContent
          id="journal"
          class="mb-16"
          :title="$t('moreWaysPage.journal.title')"
          :show-dropdown="true"
          :dropdown-title="$t('moreWaysPage.journal.dropdown.title')"
          dropdown-button-color="secondaryBackgroundEmphasis"
          dropdown-color="secondaryBackground"
          feature-image="journal_feature.jpeg"
          banner-icon="journal_icon.svg"
          banner-color="text"
          :buttonText="$t('moreWaysPage.journal.buttonText')"
      >
        <template v-slot:content-text>
          <div class="below-header__text">
            {{ $t('moreWaysPage.journal.subtitle.line1') }}
          </div>
          <div class="below-header__text indented-text">
            <span class="large-text">{{ $t('moreWaysPage.journal.subtitle.line2.part1') }}</span>{{ $t('moreWaysPage.journal.subtitle.line2.part2') }}
          </div>
        </template>
        <template v-slot:dropdown-content >
          <div class="dropdown-content__text">
            <v-list-item class="mb-4 mb-md-8">
              {{ $t('moreWaysPage.journal.dropdown.item1') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item2') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item3') }}
            </v-list-item>
            <v-list-item class="mb-4 mb-md-8">
              {{ $t('moreWaysPage.journal.dropdown.item4') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item5') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item6') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item7') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item8') }}
            </v-list-item>
            <v-list-item>
              {{ $t('moreWaysPage.journal.dropdown.item9') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item10') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item11') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item12') }}<br/>
              {{ $t('moreWaysPage.journal.dropdown.item13') }}
            </v-list-item>
          </div>
        </template>
        <template v-slot:banner-text-content>
          <span class="banner-text-content">
            {{ $t('moreWaysPage.journal.bannerText.part1') }}<span class="font-weight-bold">{{ $t('moreWaysPage.journal.bannerText.part2') }}</span>{{ $t('moreWaysPage.journal.bannerText.part3') }}
          </span>
        </template>
        <template v-slot:action-text>
          {{ $t('moreWaysPage.journal.promptText') }}
        </template>
      </WaysToJamInfoContent>
      <WaysToJamInfoContent
          id="event"
          class="mb-16"
          :title="$t('moreWaysPage.event.title')"
          feature-image="event_feature.jpeg"
          :show-dropdown="true"
          :dropdown-title="$t('moreWaysPage.event.dropdown.title')"
          dropdown-button-color="text"
          dropdown-color="secondary"
          banner-icon="event_icon.svg"
          banner-color="secondaryBackgroundEmphasis"
          :buttonText="$t('moreWaysPage.event.buttonText')"
      >
        <template v-slot:content-text>
          <div class="below-header__text">
            {{ $t('moreWaysPage.event.subtitle.line1') }}<br />
            {{ $t('moreWaysPage.event.subtitle.line2') }}
          </div>
        </template>
        <template v-slot:dropdown-content >
          <div class="dropdown-content__text">
            <v-list-item>
              {{ $t('moreWaysPage.event.dropdown.item1') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item2') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item3') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item4') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item5') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item6') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item7') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item8') }}<br/>
              {{ $t('moreWaysPage.event.dropdown.item9') }}
            </v-list-item>
          </div>
        </template>
        <template v-slot:banner-text-content>
          <span class="banner-text-content">
            {{ $t('moreWaysPage.event.bannerText') }}
          </span>
        </template>
        <template v-slot:action-text>
          <span style="font-weight: 600;">{{ $t('moreWaysPage.event.promptText.line1') }}</span><br />
          {{ $t('moreWaysPage.event.promptText.line2') }}<br />
          {{ $t('moreWaysPage.event.promptText.line3') }}

        </template>
      </WaysToJamInfoContent>
      <WaysToJamInfoContent
          id="vip"
          :title="$t('moreWaysPage.vip.title')"
          feature-image="vip_feature.jpeg"
          :show-dropdown="true"
          :dropdown-title="$t('moreWaysPage.vip.dropdown.title')"
          dropdown-button-color="primaryAction"
          dropdown-color="primary"
          banner-icon="vip_icon.svg"
          banner-color="secondary"
          :buttonText="$t('moreWaysPage.vip.buttonText')"
          :back-to-top-variant="true"
          show-back-to-top
      >
        <template v-slot:content-text>
          <div class="below-header__text">
            {{ $t('moreWaysPage.vip.subtitle.line1') }}
            <div class="indented-text">
              {{ $t('moreWaysPage.vip.subtitle.line2.part1') }}<span class="large-text">{{ $t('moreWaysPage.vip.subtitle.line2.part2') }}</span><br/>
              {{ $t('moreWaysPage.vip.subtitle.line3') }}
            </div>
          </div>
        </template>
        <template v-slot:dropdown-content >
          <div class="dropdown-content__text">
            <v-list-item class="mb-sm-4 mb-md-8">
              {{ $t('moreWaysPage.vip.dropdown.item1') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item2') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item3') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item4') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item5') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item6') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item7') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item8') }}<br/>
              {{ $t('moreWaysPage.vip.dropdown.item9') }}
            </v-list-item>
          </div>
        </template>
        <template v-slot:banner-text-content>
          <span class="banner-text-content vip">
            {{ $t('moreWaysPage.vip.bannerText') }}
          </span>
        </template>
        <template v-slot:action-text>

          {{ $t('moreWaysPage.vip.promptText.line1') }}<br />
          <span class="text-uppercase">{{ $t('moreWaysPage.vip.promptText.line2') }}</span><br />
        </template>
      </WaysToJamInfoContent>
    </v-container>
  </v-container>
</template>

<script>
import IdeaCard from '@/components/IdeaCard.vue';
import WaysToJamInfoContent from '../components/WaysToJamInfoContent.vue';
import AnimationTriggerMixin from '@/mixins/AnimationTriggerMixin';

export default {
  name: "MoreWaysToJam",
  components: {
    IdeaCard,
    WaysToJamInfoContent
  },
  mixins: [AnimationTriggerMixin],
  data() {
    return {
      showSubtitle: false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

span {
  color: inherit;
}

.header {
  text-align: center;
  margin: 100px 0px;
}

.header__title {
  margin-bottom: 80px;
}

.header__subtitle {
  color: var(--v-secondary-base);
}

.header__subtitle h3 {
  color: var(--v-secondary-base);
  font-size: 54px;
  font-weight: 600;
  line-height: 110.5%; /* 59.67px */
}

.emphasized-subtitle {
  color: var(--v-primary-base);
  font-size: 82px;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px auto;
}

.content-container {
  max-width: 1280px;
  padding: 100px 80px 0px;
}

.below-header__text .large-text {
  font-size: 48px;
}

.indented-text {
  margin-left: 60px;
}

.banner-text-content span {
  color: inherit;
}

.banner-text-content .emphasized {
  color: var(--v-textOnSecondary-base);
  font-size: 38px;
}

.banner-text-content.vip {
  font-size: 48px;
}

.dropdown-content__text > .v-list-item {
  color: var(--v-textOnSecondary-base) !important;
  padding: 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.2px;
  text-transform: uppercase;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    margin-bottom: 70px;
  }

  .header__title {
    margin-bottom: 30px;
  }

  .header__title, .emphasized-subtitle {
    font-size: 48px;
  }

  .header__subtitle h3 {
    font-size: 32px;
  }

  .divider-container {
    margin: 125px auto;
  }

  .divider-container .v-image {
    display: none;
  }

  .content-container {
    padding: 60px 30px 0px;
  }

  .below-header__text .large-text {
    font-size: 28px;
    font-weight: 600;
  }

  .banner-text-content.vip {
    font-size: 36px;
  }

  .indented-text {
    font-size: 28px;
  }

  .dropdown-content__text > .v-list-item {
    font-size: 16px;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .header {
    margin-bottom: 40px;
  }

  .header__title {
    margin-bottom: 22px;
  }

  .header__title, .emphasized-subtitle {
    font-size: 36px;
  }

  .header__subtitle h3 {
    font-size: 24px;
  }

  .divider-container {
    margin: 40px auto 76px;
  }

  .divider-container .v-image {
    display: block;
  }

  .below-header__text .large-text, .indented-text {
    font-size: 16px;
  }

  .banner-text-content.vip {
    font-size: 24px;
  }

  .indented-text {
    margin-left: 0px;
  }

  .banner-text-content .emphasized {
    font-size: 16px;
  }

  .max-width {
    max-width: 141px;
  }

  .dropdown-content__text {
    text-align: center;
  }

  .dropdown-content__text > .v-list-item {
    justify-content: center;
  }
}
</style>