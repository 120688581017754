<template>
  <v-container class="pa-0" fluid>
    <div v-intersect.once="(entries) => onIntersect(entries, 'showTitle')">
      <v-slide-x-transition>
        <div v-if="showTitle" class="header mb-4 mb-sm-3 mb-md-7">
          <h1 class="header__title mx-0 mr-sm-5">{{ title }}</h1>
          <div class="header__bar" />
        </div>
      </v-slide-x-transition>
    </div>
    <v-row no-gutters>
      <v-col class="col-12 col-sm-7 d-flex flex-column justify-center">
        <div
          v-intersect.once="(entries) => onIntersect(entries, 'showContent')"
          class="content d-flex align-center justify-space-between"
        >
          <v-scale-transition>
            <p v-if="showContent" class="content-text">
              <slot name="content-text" />
            </p>
          </v-scale-transition>
        </div>
      </v-col>
      <v-col class="col-12 col-sm-5 d-flex flex-column justify-center">
        <div v-intersect.once="(entries) => onIntersect(entries, 'showFeatureImage')">
          <v-scale-transition>
            <v-img
              v-if="showFeatureImage"
              class="feature-image ml-auto"
              :src="resolveUrl(featureImage)"
              :alt="title"
              contain
              :position="imagePosition"
            />
          </v-scale-transition>
        </div>
      </v-col>
    </v-row>
    <div v-intersect.once="(entries) => onIntersect(entries, 'showBanner')">
      <div :class="bannerColor + ' banner-content'">
        <v-scale-transition>
          <div v-if="showBanner" class="banner-image">
            <v-img
              :src="resolveUrl(bannerIcon)"
              contain
              :max-width="bannerIconWidth"
              :position="bannerIconPosition"
            />
          </div>
        </v-scale-transition>
        <h3 class="banner-text">
          <slot name="banner-text-content" />
        </h3>
      </div>
    </div>
    <div v-intersect.once="(entries) => onIntersect(entries, 'showActionPrompt')">
      <v-scale-transition>
        <div v-if="showActionPrompt" class="action-prompt">
          <h4 class="action-text mb-10 mb-md-0">
            <slot name="action-text" />
          </h4>
          <v-btn
            class="btn btn--primary ml-0 ml-sm-11"
            color="primaryAction"
            depressed
            :to="{ path: toPage }"
          >
            <span class="btn__text btn__text--white">{{ buttonText }}</span>
          </v-btn>
        </div>
      </v-scale-transition>
    </div>
    <div
      v-if="showDropdown"
      class="dropdown end-variant"
    >
      <v-expansion-panels
        v-model="dropdownActive"
        accordion
        flat
        tile
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            class="dropdown-btn px-7"
            :color="dropdownButtonColor"
            hide-actions
            v-slot="{ open }"
          >
            <div class="dropdown-btn-text text-align-center">
              <span class="btn__text btn__text--white mr-2">
                {{ dropdownTitle }}
              </span>
              <v-icon
                :class="open ? 'open' : 'closed'"
                color="textOnSecondary"
              >
                mdi-triangle-down
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            depressed
            class="dropdown-menu"
          >
            <div
              class="triangle"
              :style="`border-bottom-color: var(--v-${dropdownColor}-base);`"
            />
            <v-list
              class="py-5 px-2 pa-sm-4 py-md-4 px-md-7"
              tile
              :color="dropdownColor"
              style="max-width: 212px; margin: 0 auto;"
            >
              <slot name="dropdown-content" />
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <BackToTopLink v-if="showBackToTop" :dark="backToTopVariant" />
  </v-container>
</template>

<script>
import BackToTopLink from "./BackToTopLink.vue";
import AnimationTriggerMixin from "@/mixins/AnimationTriggerMixin";

export default {
  name: "WaysToJamInfoContent",
  mixins: [AnimationTriggerMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
    dropdownTitle: {
      type: String,
      default: "",
    },
    dropdownButtonColor: {
      type: String,
      default: "primary",
    },
    dropdownColor: {
      type: String,
      default: "primary",
    },
    featureImage: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: String,
      default: "right",
    },
    bannerIcon: {
      type: String,
      required: true,
    },
    bannerIconWidth: {
      type: String,
      default: "135px",
    },
    bannerIconPosition: {
      type: String,
      default: "",
    },
    bannerColor: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    toPage: {
      type: String,
      default: "/signup",
    },
    showBackToTop: {
      type: Boolean,
      default: false,
    },
    backToTopVariant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownActive: false,
      showTitle: false,
      showContent: false,
      showFeatureImage: false,
      showBanner: false,
      showActionPrompt: false,
    };
  },
  methods: {
    resolveUrl(image) {
      return require(`@/assets/${image}`);
    },
  },
  components: { BackToTopLink },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.header {
  display: flex;
  align-items: center;
}

.header__title {
  line-height: 150%;
}

.header__bar {
  display: flex;
  flex: 1 0 0;
  height: 65px;
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.dropdown-menu {
  box-shadow: none;
  position: absolute;
  padding: 0px;
  left: 0;
  right: 0;
}

.dropdown-menu .v-list {
  width: 240px;
}

.dropdown-btn-text {
  display: flex;
  align-items: center;
}

.dropdown-btn-text .v-icon {
  margin-top: 4px;
  transition: transform 0.3s ease-in-out;
}

.dropdown-btn-text .closed {
  transform: rotate(0deg);
}

.dropdown-btn-text .open {
  transform: rotate(180deg);
}

.dropdown.end-variant {
  width: 380px;
  max-width: 100%;
  margin: 20px 80px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.dropdown-btn {
  width: 100%;
  min-height: 65px;
  padding: 0px 20px;
}

.dropdown-btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
}


.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 28px solid var(--v-primary-base);
}

.feature-image {
  min-height: 200px;
}

.banner-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 182px;
  overflow: hidden;
}

.content-text {
  color: var(--v-text-base);
  font-size: 36px;
  font-weight: 400;
  line-height: 150%; /* 54px */
}

.banner-image {
  position: absolute;
  left: 5%;
}

.banner-text {
  z-index: 1;
  margin: 0 185px;
}

.banner-text, .banner-text span {
  color: var(--v-textOnSecondary-base);
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.action-prompt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 40px 80px 0px 0px;
}

.action-prompt > .action-text {
  color: var(--v-primaryAction-base) !important;
  font-size: 36px;
  font-weight: 400;
  line-height: 150%; /* 54px */
  text-align: right;
}

.action-text span {
  color: var(--v-primaryAction-base);
}

.action-prompt > .btn {
  width: 380px;
  max-width: 100%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    margin-bottom: 10px;
  }

  .header__title {
    font-size: 48px;
  }

  .header__bar {
    height: 34px;
  }

  .content-text {
    font-size: 24px;
  }

  .dropdown-btn {
    max-height: 40px;
  }

  .dropdown-btn-text .v-icon {
    margin-top: 0px;
  }

  .dropdown-btn-text span {
    text-transform: uppercase;
  }

  .dropdown.end-variant {
    width: 320px;
    margin: 16px 0px 0px auto;
  }

  .banner-content {
    height: 120px;
    overflow: visible;
  }

  .banner-image {
    left: 10px;
    width: 100px;
  }

  .banner-text {
    margin: 0 110px;
  }

  .banner-text, .banner-text span {
    font-size: 28px;
    line-height: normal;
  }

  .action-prompt {
    margin: 30px 0px 0px;
    flex-direction: column;
    align-items: end;
  }

  .action-prompt > .action-text {
    font-size: 24px;
  }

  .action-prompt > .btn {
    width: 320px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .header {
    flex-direction: column-reverse;
  }

  .header__title {
    font-size: 36px;
    text-align: center;
  }

  .header__bar {
    width: 100%;
    flex: 0 0 16px;
    border-radius: 3px;
  }

  .dropdown.end-variant {
    width: 100%;
    margin: 35px 0px 0px auto;
  }

  .dropdown-btn {
    height: 48px;
    max-height: 48px;
    margin: 0 auto;
    min-height: 0;
    width: 200px;
    max-width: 100%;
  }

  .dropdown-btn-text span,
  .dropdown-btn-text .v-icon {
    font-size: 16px;
  }

  .dropdown-menu {
    position: relative;
  }

  .triangle {
    margin: 4px auto 0;
  }

  .feature-image {
    max-height: 260px;
    margin-top: 10px;
  }

  .banner-content {
    min-height: 64px;
    height: fit-content;
    margin-top: 0px;
  }

  .content-text {
    font-size: 16px;
    text-align: center;
    margin: 0 auto 16px;
  }

  .banner-image {
    width: 44px;
  }

  .banner-text {
    margin: 0 60px;
  }

  .banner-text, .banner-text span {
    font-size: 16px;
  }

  .action-prompt {
    flex-direction: column;
    align-items: center;
  }

  .action-prompt > .action-text {
    text-align: center;
    font-size: 16px;
  }

  .action-prompt > .btn {
    width: 230px;
    max-width: 100%;
  }
}
</style>
